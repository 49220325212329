<template>
  <div
      class="
      border-0
      pt-5
      d-flex
      justify-content-between
      flex-column flex-xxl-row
      align-items-start align-items-xxl-center
    "
  >
    <h3 class="ms-9">
      <span class="card-label fw-bolder fs-3 mb-1">{{ tableName }}</span>
    </h3>
    <div
        class="
        flex-column flex-xxl-row
        d-flex
        justify-content-between
        align-items-start align-items-xxl-center
        me-9
        ms-xxl-0 ms-md-7 ms-9
      "
    >
      <!--input to start and end date same line-->
      <div v-if="showFilters && showDatesFilters" class="me-6">
        <span class="fs-6 d-xxl-flex ms-3">
          <span class="fw-bold" style="align-self:center;">De:</span>
          <input
              type="date"
              class="form-control form-control-sm form-control-solid me-1"
              v-model="startDate"
              @change="queryForOccurences"
          />
          <span class="fw-bold" style="align-self:center;">Até:</span>
          <input
              type="date"
              class="form-control form-control-sm form-control-solid m-xxl-0 mb-2 me-1"
              v-model="endDate"
              @change="queryForOccurences"
          />
        </span>
      </div>

      <!--begin::Label-->
      <label v-if="showFilters && filterStatus.length" class="fs-6 fw-bold me-6">Filtrar por:</label>

      <!--begin::Select-->
      <div v-if="showFilters && filterStatus.length" class="me-6 mb-2 mb-xxl-0">
        <Field
            name="status"
            data-control="select2"
            data-hide-search="true"
            data-placeholder="Selecione o estado..."
            class="form-select form-select-solid fs-7 text-muted"
            style="min-width: 150px; max-width: 150px;"
            as="select"
            @change="updateStatus"
            v-model="status"
        >
          <option class="" value="">Todos</option>
          <option
              v-for="(item, index) in filterStatus"
              :key="index"
              :value="item"
          >
            {{ item }}
          </option>
        </Field>
      </div>

      <div v-if="showFilters " class="me-5">
        <span
            class="
            svg-icon svg-icon-3 svg-icon-gray-500
            position-absolute
            translate-middle
            ps-10
            pt-11
          "
        >
          <span class="svg-icon svg-icon-2">
            <inline-svg src="media/icons/duotune/general/gen004.svg"/>
          </span>
        </span>
        <input
            type="text"
            class="form-control form-control-sm form-control-solid ps-10"
            style="min-width: 150px; max-width: 150px;"
            v-model="searchInput"
            v-on:keyup="queryForOccurences"
            placeholder="Pesquisar..."
        />
      </div>

      <div
      >
        <v-pagination
            v-if="pages"
            style="color: red; justify-content: end;"
            v-model="page"
            :pages="pages"
            :range-size="0"
            :hideFirstButton="true"
            :hideLastButton="true"
            active-color="#efefef"
            @update:modelValue="paginatorHandler"
        />
      </div>

      <!--end::Menu-->
    </div>
  </div>
</template>

<script>
import VPagination from "@hennge/vue3-pagination";
import "@hennge/vue3-pagination/dist/vue3-pagination.css";
import {ErrorMessage, Field, Form} from "vee-validate";

export default {
  name: "CustomTableTopFilter",
  components: {
    Field,
    VPagination,
  },
  data() {
    return {
      page: 1,
      status: "",
      searchInput: "",
      startDate: "",
      endDate: "",
    };
  },
  watch: {
    pageProp() {
      this.page = this.pageProp;
    },
  },
  props: {
    tableName: String,
    pageProp: Number,
    pages: Number,
    filterStatus: Array,
    showFilters: {
      type: Boolean,
      default: true,
    },
    showDatesFilters: {
      type: Boolean,
      default: true,
    },
  },
  emit: ["paginatorHandler", "updateStatus", "queryForOccurences"],

  methods: {
    getMaxWidth() {
      if (this.pages) {
        return {
          minWidth: "250px",
        };
      }
    },

    /*
    * ------------------EMITS------------------
    */

    paginatorHandler() {
      this.$emit("paginatorHandler", this.page);
    },
    updateStatus() {
      this.$emit("updateStatus", this.status);
    },
    queryForOccurences() {
      this.$emit("queryForOccurences", {
        searchInput: this.searchInput,
        startDate: this.startDate, endDate: this.endDate
      });
    },
  },
  mounted() {
    let startDate = new Date();
    startDate.setFullYear(startDate.getFullYear() - 1);
    this.startDate = startDate.toLocaleDateString("pt-PT").replace(/\//g, "-").split("-").reverse().join("-");
  },
};
</script>

<style>
</style>