
import {defineComponent} from "vue";
import Dropdown2 from "@/components/dropdown/Dropdown2.vue";
import CustomTableTopFilter from "@/components/customTables/CustomTableTopFilter.vue";
import {boolean} from "yup/lib/locale";

/* eslint-disable */
export default defineComponent({
  name: "CustomTableProducts",
  data() {
    return {
      rowsToShow: [""],
    };
  },
  components: {
    CustomTableTopFilter,
  },
  props: {
    widgetClasses: String,
    tableName: String,
    list: Array,
    showStatus: {
      type: Boolean,
      default: true,
    },
    pageProp: Number,
    pages: Number,
    showFilters: {
      type: Boolean,
      default: true,
    },
    filterStatus: Array,
  },
  computed: {},
  emit: ["paginatorHandler", "updateStatus", "queryForProduct"],
  methods: {

    /*
   * ------------------EMITS------------------
   */

    updateStatus(status) {
      this.$emit("updateStatus", status);
    },
    queryForProduct(data) {
      this.$emit("queryForProduct", data);
    },

    paginatorHandler(page) {
      this.$emit("paginatorHandler", page);
    },
  },

});
