<template>
  <div>
    <CustomTableProducts
      style="max-width: fit-content; min-width: 102%;"
      :list="list"
      :pages="totalPages"
      :pageProp="page"
      :showFilters="true"
      :filterStatus=[]
      tableName="Produtos"
      @paginatorHandler="paginatorHandler"
      @updateStatus="updateStatus"
      @queryForProduct="queryForProduct"
    />
  </div>
</template>



<script>
import CustomTableProducts from "@/components/customTables/CustomTableProducts.vue";
import store from "@/store";
import ApiService from "@/core/services/ApiService";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
export default {
  name: "AllProducts",

  components: {
    CustomTableProducts,
  },
  data() {
    return {
      list: [],
      page: 1,
      totalPages: 0,
      query: "",
      families: [],
      status: "",
      filterstatus: [],
    };
  },
  methods: {
    orderFamilies(families) {
      //remove empty ref
      families = families.filter((family) => family.ref.trim().length);

      //order by ref
      families.sort((a, b) => {
        if (a.nome < b.nome) return -1;
        if (a.nome > b.nome) return 1;
        return 0;
      });

      //remove duplicated ref
      families = families.filter((family, index) => {
        if (index === 0) return true;
        if (family.ref === families[index - 1].ref) return false;
        return true;
      });
      
      //ctreate array with nomes only
        this.filterstatus = families.map((family) => family.nome);
        
      return families;
    },
    loadFamilies() {
      ApiService.get("products/families", "?PageSize=99999").then((res) => {
        this.families = this.orderFamilies(res.data.data);
      });
    },
    getProducts() {
      let queryString = `?PageNumber=${this.page}&numCliente=${store.getters.currentUser.numcl} `;

      //get ref from family
        if (this.status) {
            let ref
            //filter families and search for ref
            this.ref = this.families.filter((family) => {
                if (family.nome === this.status) {
                    return family.ref;
                }
            });
            //get last index from array
            ref = this.ref[this.ref.length - 1].ref;
            queryString += `&familia=${ref}`;


      }

      if (this.query) {
        queryString += `&Design=${this.query}`;
      }

      ApiService.get("/Products/ByClient", queryString)
        .then((response) => {
          this.list = response.data.data;
          this.totalPages = response.data.totalPages;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    paginatorHandler(page) {
      this.page = page;
      this.getProducts();
    },
    updateStatus(status) {
      this.status = status;
      this.getProducts();
    },
    queryForProduct(data) {
      this.page = 1;
      this.query = data.searchInput;
      this.getProducts();
    },
  },
  mounted() {
    setCurrentPageBreadcrumbs("Produtos Adquiridos");
    this.getProducts();
    this.loadFamilies();
  },
};
</script>

<style>
</style>